import img from "../../assets/images/multichanel.png";

export default function Multichanel() {
    return (
        <div className="flex items-center justify-center w-full gap-4 py-10 md:flex-row flex-col ">
            <div className="flex flex-col items-start justify-center w-full gap-8">
                <h1 className='txt-primary text-3xl font-bold'>
                    Atenda seus clientes de forma integrada
                </h1>
                <p className='txt-secondary text-xl'>
                    Esqueça a necessidade de trocar de aplicativos!. Centralize suas conversas do Telegram, WhatsApp, Instagram e muito mais em um único lugar!
                </p>
                <a href="https://wa.me/554598420630" target="_blank" rel="noreferrer" className="text-white w-full text-center rounded-full px-8 py-2 bg-blue-500 hover:bg-blue-700">
                    Entre em contato
                </a>
            </div>
            <div className="w-full flex justify-center">
                <img src={img} alt="img_multicachel" className="w-[30vh] md:w-[30vw] object-scale-down" />
            </div>
            {/* <Marquee className="mt-10 w-full">
                {marqueeImgs.map((img, index) => (
                    <img key={index} src={img} alt="marquee" className="mr-[10vw]" />
                ))}
            </Marquee> */}
        </div>
    );
}