import React, { useEffect } from 'react'; const ChatWidget: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.uchat.com.au/js/widget/tjvmfenz0etgam3q/float.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script); return () => {
            document.body.removeChild(script);
        };
    }, []); return null;
}; export default ChatWidget;