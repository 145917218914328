interface SolutionCardProps {
    title: string;
    description: string;
    img: string;
}

export default function SolutionCard({ title, description, img }: SolutionCardProps) {
    return (
        <div className="flex flex-col items-center justify-center w-full gap-4">
            <div className="flex gap-10 container px-8 md:flex-row flex-col items-center justify-center md:pb-0 pb-10">
                <img src={img} alt="solution" className="lg:w-[600px] md:w-[300px] w-full h-[20vh] object-cover rounded-lg " />
                <div className="flex flex-col gap-4 w-full">
                    <b className="text-2xl font-bold">{title}</b>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}