import React, { useState } from 'react';
import rmsLogo from '../../assets/images/ChatRMS_logo_preto.png';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="bg-white shadow-md sticky top-0 z-[999]">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex items-center">
                        <a href="/" className="text-xl font-bold text-gray-800">
                            <img src={rmsLogo} alt="RMS Logo" className="h-8 w-auto object-scale-down" />
                        </a>
                    </div>
                    <div className="hidden md:flex items-center space-x-4">
                        {/* <Link to="/" className="text-gray-800 hover:text-blue-600">
                            Início
                        </Link> */}
                        {/* <Link to="/aviso-de-privacidade" className="text-gray-800 hover:text-blue-600">
                            Aviso de Privacidade
                        </Link> */}
                        <a href="https://wa.me/554598420630" target="_blank" rel="noreferrer" className="text-white rounded-full px-8 py-2 bg-blue-500 hover:bg-blue-700">
                            Entre em contato
                        </a>
                    </div>
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={toggleMenu}
                            className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                {/* Menu Mobile */}
                {isMenuOpen && (
                    <div className="md:hidden pb-5 flex flex-col gap-2">
                        {/* <Link to="/" className="block text-gray-800 text-center py-2">
                            Início
                        </Link> */}
{/* 
                        <Link to="/aviso-de-privacidade" className="block text-gray-800 text-center py-2">
                            Aviso de Privacidade
                        </Link> */}

                        <Link to="/" className="block text-white text-center rounded-full px-8 py-2 bg-blue-500 hover:bg-blue-700">
                            Entre em contato
                        </Link>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default NavBar;