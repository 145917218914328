interface AssistenteProps {
    name: string;
    content: string;
    image: string;
    active: boolean;
    onClick: () => void;
}

export default function Assistente({ name, content, image, active, onClick }: AssistenteProps) {
    return (
        <div className="px-8 md:px-4 ">
            <div
                className={`${active ? 'border-blue-500' : ''} border-2 rounded-xl p-2 text-center flex flex-col items-center justify-center cursor-pointer hover:bg-blue-100 min-h-[24vh] lg:w-[18vw] w-full`}
                onClick={onClick}
            >
                <div className={` ${active ? 'bg-blue-500' : 'bg-blue-300'} rounded-full flex items-center justify-center h-16 w-16`}>
                    <img src={image} className='h-16 w-16 object-scale-down' alt={name} />
                </div>
                <p className='font-bold lg:text-xl text-sm mt-4'>{name}</p>
                <p className='mt-2 text-center lg:text-normal text-sm'>{content}</p>
            </div>
        </div>
    );
}