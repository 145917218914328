import SolutionCard from "../SolutionCard/SolutionCard";
import img1 from "../../assets/images/solution1.png";
import img2 from "../../assets/images/solution2.png";
import img3 from "../../assets/images/solution3.png";

export default function Solutions() {
    return (
        <div className="flex flex-col items-center justify-center w-full gap-10 py-10 mt-10" id='services'>
            <b className="text-3xl font-bold text-center">Soluções para o seu negócio</b>
            <div className="flex flex-col gap-5">
                <SolutionCard title="Chatbots Personalizados" description="Desenvolvemos chatbots sob medida para atender às necessidades específicas do seu negócio. Nossos chatbots são capazes de responder a perguntas frequentes, processar pedidos e fornecer suporte técnico, tudo de forma automatizada e eficiente." img={img1} />
                <SolutionCard title="Integração Multicanal" description="Conecte-se com seus clientes em múltiplos canais de comunicação, incluindo WhatsApp, Messenger, Instagram, e-mail e muito mais. Ofereça uma experiência de atendimento consistente e eficiente, simplifique a gestão de mensagens e aumente a produtividade da sua equipe." img={img2} />
                <SolutionCard title="Ofereça Suporte 24/7" description="Ofereça suporte eficiente e uniforme em todos os canais de comunicação. Não perca nenhuma interação com seus clientes, garantando a satisfação e a qualidade do atendimento." img={img3} />
            </div>
        </div>
    );
}