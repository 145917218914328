import logo from '../../assets/images/logo_rms.png';
import whatsapp from '../../assets/svgs/whatsapp-logo.svg';
import email from '../../assets/svgs/envelope.svg';

export default function Footer() {
    return (
        <footer className="flex flex-col items-center px-6 py-2 lg:py-6 gap-4 text-sm z-50 bottom-0 w-full">
            <div className="flex justify-between w-full gap-2">
                <div className='flex flex-col items-start'>
                    <img src={logo} alt="Logo da Empresa" className="h-8 object-scale-down mb-2" />
                </div>
                <div className="flex gap-4">
                    <a href="https://wa.me/554598420630" target="_blank" rel="noreferrer">
                        <img src={whatsapp} alt="Whatsapp" className="h-6 w-auto object-scale-down" />
                    </a>
                    <a href="mailto:atendimento@rmssofthouse.com" target="_blank" rel="noreferrer">
                        <img src={email} alt="Email" className="h-6 w-auto object-scale-down" />
                    </a>

                </div>
            </div>
            <div className="text-center text-gray-600 text-xs">
                <b>Aviso de Privacidade</b><br /><br />
                Nosso compromisso com a privacidade visa garantir a proteção dos dados pessoais e segurança das informações, em conformidade com as legislações vigentes.
                No momento, nosso site não utiliza cookies para coletar informações. Caso isso mude em atualizações futuras, uma política de cookies será publicada. <br /><br />
                RMS Software House<br />
                55.617.703/0001-55
            </div>

        </footer>
    );
}