import PlatformCard from "../PlatformCard/PlatformCard";
import img1 from "../../assets/svgs/connection.svg";
import img2 from "../../assets/svgs/notification.svg";
import img3 from "../../assets/svgs/headset.svg";

export default function Platforms() {
    return (
        <div className="flex flex-col items-center justify-center" id='about'>
            <div className="flex flex-col items-center justify-center w-full gap-10 py-10 lg:flex-row my-[2vh]">
                <section id='multiCanal' className="lg:w-[120%]">
                    <div className='flex flex-col gap-4'>
                        <h1 className='txt-primary text-3xl font-bold text-center lg:text-left'>
                            Conecte-se com Seus Clientes em Múltiplos Canais de Comunicação
                        </h1>
                        <p className='txt-secondary text-xl text-center lg:text-justify'>
                            Amplie seu alcance e melhore a interação com seus clientes ao integrar diversos canais de comunicação online em um único lugar. Ofereça uma experiência de atendimento consistente e eficiente, simplifique a gestão de mensagens e aumente a produtividade da sua equipe.
                        </p>
                    </div>
                </section>
                <section className="flex gap-4 items-center md:flex-row flex-col">
                    <PlatformCard
                        content="Conecte-se com clientes via WhatsApp, Messenger, Instagram, e-mail e mais, em um só lugar"
                        img={img1}
                    />
                    <PlatformCard
                        content="Gerencie mensagens facilmente, aumente a produtividade e não perca nenhuma interação"
                        img={img2}
                    />
                    <PlatformCard
                        content="Ofereça suporte eficiente e uniforme em todos os canais de comunicação"
                        img={img3}
                    />
                </section>
            </div>
        </div>
    );
}