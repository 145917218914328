import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ChatWidget from './components/ChatWidget/ChatWidget';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aviso-de-privacidade" element={<PrivacyPolicy />} />
      </Routes>
      <ChatWidget />
    </Router>
  );
}

export default App;
