interface Props {
    content: string;
    img: string;
}

export default function PlatformCard(props: Props) {
    return (
        <div className="bg-primary rounded-lg w-full flex flex-col gap-5 items-center justify-start px-2 w-full md:min-h-[30vh] min-h-[16vh]">
            <img src={props.img} className="object-scale-down mt-8" alt="logo" />
            <p className="text-white text-xl text-center lg:text-justify my-4">{props.content}</p>
        </div>
    );
}