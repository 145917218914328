import logo from '../../assets/images/logo_rms.png';
import Footer from '../../components/Footer/Footer';

export default function PrivacyPolicy() {
    return (
        <div className='flex flex-col'>
            <div className='relative flex flex-col w-full lg:px-20 fade-in' id='privacyPolicy'>
                <div className="flex flex-col gap-10 text-center p-4 lg:mx-[25%] lg:mt-20">
                    <img src={logo} alt="Logo" className="h-16 w-auto object-scale-down" />
                    <b className="text-2xl font-bold">Aviso de Privacidade ChatRMS</b>
                    <p className="text-lg text-justify">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi fuga quidem, rerum alias sapiente id dolore explicabo, quas ab animi odit delectus obcaecati, eum molestias atque consequuntur libero perferendis! Aperiam.
                    </p>
                </div>
            </div>
            <div className='absolute bottom-0 w-full'>
                <Footer />
            </div>
        </div>
    );
}