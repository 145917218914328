import './Home.module.scss';
import Hero from '../../components/Hero/Hero';
import Platforms from '../../components/Platforms/Platforms';
import Divider from '../../components/Divider/Divider';
import Multichanel from '../../components/Multichanel/Multichanel';
import Solutions from '../../components/Solutions/Solutions';
import Footer from '../../components/Footer/Footer';

export default function Home() {
    return (
        <div className='overflow-hidden lg:px-20'>
            <Hero />
            <Platforms />
            <Divider />
            <Multichanel />
            <Divider />
            <Solutions />
            <Footer />
        </div>
    );
}