// NewCarousel.tsx
import React from 'react';
import Carousel from "react-multi-carousel";
import Assistente from "../Hero/components/Assistente";
import 'react-multi-carousel/lib/styles.css';
import robot from '../../assets/svgs/robot.svg';
import BotInterface from "../../interfaces/BotInterface";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

interface NewCarouselProps {
    items: BotInterface[];
    activeBot: BotInterface | null;
    setActiveBot: (bot: BotInterface) => void;
}

const NewCarousel: React.FC<NewCarouselProps> = ({ items, activeBot, setActiveBot }) => {
    return (
        <div className="lg:w-[64vw] w-full">
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={false}
                centerMode={false}
                containerClass="container-with-dots"
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {items.map((bot, index) => (
                    <Assistente
                        key={index}
                        name={bot.name}
                        image={robot}
                        active={activeBot === bot}
                        onClick={() => setActiveBot(bot)}
                        content={bot.content}
                    />
                ))}
            </Carousel>
        </div>
    );
};

export default NewCarousel;